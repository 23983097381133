import React from "react";
import Header from "../components/header/header";
import ClientLogo from "../components/clientsLogo/clientsLogo";
import Footer from "../components/footer/footer";
import sliderInner from "../images/slider/innerSlider.jpg";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";




export const ServicesWrapper = styled.div`
    max-width: 960px;
    margin:15px;
    font-family: sans-serif;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #ccc;
    min-height: 550px;
    padding: 15px;
    .panel-body {
      padding: 15px;
  }
  h2{
  color: #2e308f;
    margin-bottom: 20px;
}
  .img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
  }
.panel-body .services-wrap
{
  padding:15px 0px;
}
.panel-body .services-wrap img
{
  width: 100%;
  margin-bottom: 20px !important;
}
h4{
  margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.1;
    font-size: 18px;
    color: inherit;

}
.text-justify {
  text-align: justify;
}
p {
  margin: 0 0 10px;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
    `;

const IndexPage = () => {

  const data = useStaticQuery(graphql`
  {
    allStrapiServicePage {
      nodes {
        services {
          description
          id
          title
          image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
        }
        title
        left_content {
          list_items
        }
      }
    }
  }
`)
  const node = data.allStrapiServicePage.nodes[0];
  console.log("data services",node.services[0])
  return (
    <ServicesWrapper>
      <Header />
      <div class="inner-slider">
        <img src={sliderInner} class="img-responsive"></img>
      </div>
      <div className="container">
        <div className="row">
          <div class="col-md-3">
            <p>&nbsp;</p>
            <div class="list-group">
              {
                node.left_content.map(item => {
                  return (<a href="#ser1" class="list-group-item">{item.list_items}</a>);
                })
              }
            </div>
          </div>
          <div class="col-md-8">
            <p>&nbsp;</p>

            <h2 class="">{node.title}</h2>
            <a name="ser1"></a>
            {
              node.services.map((item, index) => {
                if (index % 2 == 0) {
                  return (
                    <div class="panel panel-default">
                      <div class="panel-body services-wrap row">
                        <div class="col-md-5">
                          <img src={item.image.childImageSharp.fluid.src} class="img-responsive"></img>
                        </div>
                        <div class="col-md-7">
                          <h4>{item.title}</h4>
                          <p class="text-justify" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        <div class="clearfix">&nbsp;</div>
                      </div>
                    </div>
                  );

                }
                else {
                  return (
                    <div class="panel panel-default">
                      <div class="panel-body services-wrap row">
                        <div class="col-md-7">
                          <h4>{item.title}</h4>
                          <p class="text-justify" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        <div class="col-md-5">
                          <img src={item.image.childImageSharp.fluid.src} class="img-responsive"></img>
                        </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>
                    </div>

                  );

                }
              })
            }
          </div>
        </div>
      </div>
      <ClientLogo />
      <Footer />
    </ServicesWrapper>
  )
}

export default IndexPage
